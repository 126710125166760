import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermsPage from "./Terms";
import PrivacyPage from "./Privacy";
import CopyrightPage from "./Copyright";
import "./App.css";
import Logo from "./Logo.png";
import BackgroundLottie from "./BackgroundLottie.js";
import { projectConfiguration } from "./projectConfiguration";

const API_DOMAIN = "https://api.hightide.link/ideas";
const { productKey, heroLineOne, heroLineTwo, heroLineThree, subtitle } = projectConfiguration;
const PRODUCT_KEY = productKey;

const LandingPage = () => {
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");
  const [invitations, setInvitations] = useState(["", ""]);
  const [invitationsSent, setInvitationsSent] = useState(0);
  const [waitlistNumber, setWaitlistNumber] = useState();
  const [error, setError] = useState();

  const onSubmitJoinWaitlist = () => {
    setError(false);
    const data = {
      productKey: PRODUCT_KEY,
      email: emailInputValue,
      referrals: [],
    };

    fetch(`${API_DOMAIN}/joinWaitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.success) {
          setSubmittedEmail(emailInputValue);
          setWaitlistNumber(responseData?.waitlistNumber);
        } else {
          setError("An error occurred joining the waitlist");
        }
      })
      .catch((error) => {
        setSubmittedEmail(emailInputValue);
        setError(error);
        console.error("Error making POST request:", error);
      });
  };

  const onSubmitInvitations = () => {
    setError(false);
    const nonEmptyInvitations = invitations.filter((element) => element !== "");
    const data = {
      productKey: PRODUCT_KEY,
      email: submittedEmail,
      referrals: nonEmptyInvitations,
    };

    fetch(`${API_DOMAIN}/joinWaitlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("POST request successful:", responseData);
        setInvitationsSent(invitationsSent + nonEmptyInvitations.length);
        setInvitations(["", ""]);
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
      });
  };

  const handleInvitationInputChange = (index, value) => {
    const updatedValues = [...invitations];
    updatedValues[index] = value;
    setInvitations(updatedValues);
  };

  const handleAddInvitation = () => {
    setInvitations([...invitations, ""]);
  };

  const variableSectionStyle = {
    backgroundColor: projectConfiguration.backgroundColor,
    color: projectConfiguration.primaryTextColor,
  };

  const variableContainerStyle = {
    fontFamily: projectConfiguration.secondaryFont,
  };

  const variableHeroTextStyle = {
    fontFamily: projectConfiguration.primaryTextColor,
  };

  const variableSubtitleTextStyle = {
    color: projectConfiguration.tertiaryTextColor,
  };

  const variableInputStyle = {
    backgroundColor: projectConfiguration.inputBackgroundColor,
    color: projectConfiguration.secondaryTextColor,
  };

  const variableInputFocusStyle = {
    backgroundColor: projectConfiguration.inputFocusBackgroundColor,
    color: projectConfiguration.inputFocusTextColor,
  };

  const variableButtonStyle = {
    backgroundColor: projectConfiguration.punchColor,
    color: projectConfiguration.buttonTextColor,
  };

  const variableDisclosureTextStyle = {
    color: projectConfiguration.secondaryTextColor,
  };

  const variableLinkStyle = {
    color: projectConfiguration.punchColor,
  };

  const variableFooterLinkStyle = {
    color: projectConfiguration.footerLinkColor,
  };

  function handleKeyDown(e) {
    if (e.key == 'Enter') {
      const submitCb = !!submittedEmail ? onSubmitInvitations : onSubmitJoinWaitlist;
      submitCb();
    }
  }
  return (
    <div className="container" style={variableContainerStyle} onKeyDown={handleKeyDown}>
      <div className="mainContent">
        <div className="section" style={variableSectionStyle}>
          <div className="wordsContent">
            <img className="logo" src={Logo} />
            <h1 className="heroText" style={variableHeroTextStyle}>
              {heroLineOne}
            </h1>
            <h1 className="heroText" style={variableHeroTextStyle}>
              {heroLineTwo}
            </h1>
            <h1 className="heroText" style={variableHeroTextStyle}>
              {heroLineThree}
            </h1>
            <p className="subtitleText" style={variableSubtitleTextStyle}>

            </p>
          </div>
        </div>
        <div className="background">
          <BackgroundLottie />
        </div>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </Router>
  )
}
